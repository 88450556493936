/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import ContactStyles from "../styles/ContactPage.module.scss"
import axios from 'axios';
import { useForm } from 'react-hook-form';

import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Header from '../components/Header';

import {
  SectionComponent,
  ParagraphComponent,
  ButtonComponent,
  HeadlineComponent,
} from '../styles/StyledComponents';
import SEO from '../utils/seo';
import Layout from '../layout/Layout';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ marginTop }) => marginTop || 0};
`;

export const FormContainer = styled.div`
  max-width: 1000px;
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const FormItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 46%;

  label {
    min-width: 100px;
    text-align: left;
  }

  input,
  select {
    padding: 6px;
    font-size: 1rem;
    width: 400px;
    border: none;
    border-bottom: 1.5px solid ${({ theme }) => theme.colors.primary};
  }

  @media screen and (max-width: 768px) {
    max-width: 300px;
    flex-direction: column;
    margin-bottom: 1.5rem;

    label {
    width: 60px;
    text-align: center;
    }

    input,
    select {
      padding: 6px;
      width: 300px;
    }
  }
`;

export const FormMessage = styled.div`
  text-align: left;
  margin-top: 1.5rem;
  margin-bottom: 5rem;
  textarea {
    margin-top: 3px;
    width: 100%;
    border: none;
    border: 1.5px solid ${({ theme }) => theme.colors.primary};
    font-size: 1rem;
    padding: 6px;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  }

  @media screen and (max-width: 768px) {
    text-align: center;
    display: flex;
    flex-direction: column;
    textarea {
      width: 300px;
    }
  }
`;

toast.configure();
const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "contact-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const [state, setState] = useState({
    name: '',
    email: '',
    phone: '',
    subject: 'Licenses',
    message: '',
  });

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const notify = () => {
    toast.info(
      'Your message has been successfully sent. We will contact you very soon!'
    );
  };

  const { register, handleSubmit } = useForm();
  const onSubmit = async formData => {
    const { name, email, phone, subject, message } = formData;
    const res = await axios.post(
      'https://teampoker-server.herokuapp.com/api/form',
      {
        name,
        email,
        phone,
        subject,
        message,
      },
      {
        headers: { 'Content-Type': undefined },
      }
    );
    notify();
    if (res.status === 200) {
      setState({
        name: '',
        email: '',
        phone: '',
        subject: 'Licenses',
        message: '',
      });
    }
  };

  return (
    <Layout>
      <SEO
        title="Contact us"
        link="https://teampoker.com/about"
        description="TeamPoker® is “The Patented Card Game Process” for the Tournament Poker Team Sports Model."
      />
      <Header image={data.background.childImageSharp.fluid} text="CONTACT US" />
      <SectionComponent mg="1.5rem">
        <HeadlineComponent>Let's work together</HeadlineComponent>
        <ParagraphComponent>
          Contact us for licenses, partnerships and live poker room software
          systems.
        </ParagraphComponent>
        <Container marginTop="4rem">
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover={false}
            style={{ fontSize: 22 }}
          />

          <form onSubmit={handleSubmit(onSubmit)}>
            <FormContainer>
              <FormRow>
                <FormItem>
                  <label htmlFor="name">Name:</label>
                  <input
                    ref={register({ required: true, maxLength: 20 })}
                    id="name"
                    name="name"
                    value={state.name}
                    type="text"
                    onChange={handleChange}
                  />
                </FormItem>

                <FormItem>
                  <label htmlFor="email">Email:</label>
                  <input
                    ref={register({ required: true })}
                    id="email"
                    name="email"
                    value={state.email}
                    type="text"
                    onChange={handleChange}
                  />
                </FormItem>
              </FormRow>

              <FormRow>
                <FormItem>
                  <label htmlFor="phone">Phone:</label>
                  <input
                    ref={register({ required: true })}
                    id="phone"
                    name="phone"
                    value={state.phone}
                    type="text"
                    onChange={handleChange}
                  />
                </FormItem>

                <FormItem>
                  <label htmlFor="subject">Subject:</label>
                  <select
                    ref={register({ required: true })}
                    id="subject"
                    name="subject"
                    onChange={handleChange}
                  >
                    <option value="licenses">Licenses</option>
                    <option value="partnership">Partnership</option>
                    <option value="software">Software Sytem</option>
                    <option value="Other">Other</option>
                  </select>
                </FormItem>
              </FormRow>

              <FormMessage>
                <label htmlFor="message">Message</label>
                <textarea
                  ref={register({ required: true })}
                  rows={6}
                  id="message"
                  name="message"
                  value={state.message}
                  type="text"
                  onChange={handleChange}
                />
              </FormMessage>
              <ButtonComponent type="submit">Submit</ButtonComponent>
            </FormContainer>
          </form>
        </Container>
      </SectionComponent>
    </Layout>
  );
};

export default Contact;
